import React, { useState } from 'react';
import { useFormik } from 'formik';
import Axios from 'axios';
import { loginSchema } from '../../Validation/UserValidation';
import './login.css';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";


export default function Login2() {
  const [username, setUsername] = useState(''); 
  const onSubmit = async (values, actions) => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    Axios.post(`${process.env.REACT_APP_API_URL}/vendor/login`, {
      username: username,
      password: password,
    })  
      .then((response) => {
        if (response.data != '-1') {
          
          setUsername(response.data.username);
          secureLocalStorage.setItem('activeUser', response.data.username);
          secureLocalStorage.setItem('mill',response.data.mill);
          secureLocalStorage.setItem('token',response.data.token);
          secureLocalStorage.setItem('role',response.data.role);
          secureLocalStorage.setItem('isLoggedIn', true);
          window.location.href = '/';
          if(response.data.username === "madura_admin" || response.data.username === "admin_healer" || response.data.username === "sirius_admin"){
            secureLocalStorage.setItem('usertype', "Admin");
          }
          else{
            secureLocalStorage.setItem('usertype', "Vendor");
          }
        } else {
          window.alert('Wrong Username or Password');
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert(err);
      });
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  console.log(errors);



    return (
      <div>
      <form className='formm' onSubmit={handleSubmit}>
        <h3>Sign In</h3>
        <div className="mb-3">
          <label htmlFor='username'>UserName</label>
          <input
            type="text"
            className="form-control"
            placeholder="UserName"
            data-testid="login-username"
            id="username"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            className2={errors.username && touched.username ? "input-error" : ""}
           
          />
          {errors.username && touched.username && <p className="error">{errors.username}</p>}
        </div>
        <div className="mb-3">
          <label htmlFor='password'>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            data-testid="login-password"
            id="password"
            value={values.password}
            onChange={handleChange}
        onBlur={handleBlur}
        className2={errors.password && touched.password ? "input-error" : ""}
             />
             {errors.password && touched.password && (
        <p className="error">{errors.password}</p>
      )}
        </div>
        <div className="mb-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Remember me
            </label>
          </div>
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>

        <p className="forgot-password text-right">
        <Link to = "/forget"> Forgot password?</Link>
         </p> 

      </form>
      </div>
    )
  };